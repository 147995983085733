*{
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Arimo", sans-serif;
}

.custom-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bgRString{
  font-size: 1.4rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #4fa3aa2b;
  /*border-bottom: 1px solid #ccc;*/
  
}

.title {
  color: #053f44;
  font-size: 3.5rem;
}

b {
  color: #053f44;
}

.content {
  display: flex;
  width: 100%;
  /* flex: 1; */
  padding: 1rem;
  
}

.column-left {
  flex: 0 1 50%;
  display: flex;  
  
}
.column-right {
  flex: 0 1 25%;
  padding: 1rem;
  box-sizing: border-box;
  margin-left: 20px;
}

.footer {
  padding: 1rem;
  background-color: #4fa3aa2b;
  /*border-top: 1px solid #ccc;*/
  text-align: left;
  
}

.input-textbox {
  background-color: #4fa3aa2b;
  width: 380px;
  height: 300px;
  resize: none;
  color: black;
}

.flex-container {
  display: flex;
  justify-content: space-between; /* Adjust this as needed */
}

/* orta boy ekranlar icin input ve bg'nin kucultulmesi */
@media (max-width: 1300px) {
  .input-textbox {
    background-color: #4fa3aa2b;
    width: 304px;
    height: 240px;
    margin: 10px;
    resize: none;
    color: black;
  }

  b{
    font-size: 1.2rem !important;
  }
  .bgRString{
    font-size: 1.2rem !important;
  }
}


/* ufak ekranlar icin input ve bg'nin alt alta hizalanması */
@media (max-width: 1200px) {

  .flex-container {
    display: block;
    justify-content: space-between; /* Adjust this as needed */
  }

  .input-textbox {
    background-color: #4fa3aa2b;
    margin: 10px;
    width: 380px;
    height: 300px;
    resize: none;
    color: black;
  }
  
}
.input-reactions,
.background-reactions {
  flex: 1;
}

.footersDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lablogoStyle{
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.sonuclarSayi{
  display: flex;
  width: 50vw;
  justify-content: space-between;
}
.rseaLogoStyle{
    width: 140px;
    height: auto;
    margin-left: 3rem;
}
