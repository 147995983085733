
.appCss{
    width: 70%;
    margin-left: 15%;
  }
  
  input {
    background-color: #f0f0f0; /* Açık gri arka plan */
    border: 1px solid #ccc;    /* Gri kenarlık */
    padding: 8px;
    border-radius: 4px;        /* Yuvarlatılmış köşeler */
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  /* Checkbox */
  input[type="checkbox"] {
    appearance: none;  /* Varsayılan checkbox stilini kaldır */
    background-color: #f0f0f0; /* Gri arka plan */
    border: 2px solid #ccc;    /* Gri kenarlık */
    width: 20px;
    height: 20px;
    border-radius: 4px;  /* Yuvarlatılmış köşeler */
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  /* Checkbox işaretli olduğunda */
  input[type="checkbox"]:checked {
    background-color: #4caf50;  /* Yeşil arka plan */
    border-color: #4caf50;      /* Yeşil kenarlık */
  }
   
  
  /* Textarea */
  textarea {
    background-color: #ffffff; /* Beyaz arka plan */
    border: 2px solid #ccc;    /* Gri kenarlık */
    border-radius: 6px;
    width: 80%;
    height: 150px;
    resize: vertical;  /* Yalnızca dikey boyutta yeniden boyutlandırılabilir */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Textarea odaklandığında */
  textarea:focus {
    border-color: #007bff; /* Mavi kenarlık */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.6); /* Mavi ışık efekti */
    outline: none;
  }
  
  .mySubmitButton {
    background-color: #4caf50; /* Yeşil arka plan */
    color: white;              /* Beyaz metin */
    border: none;              /* Kenarlık yok */
    padding: 10px 20px;        /* Yatay ve dikey padding */
    border-radius: 5px;        /* Yuvarlatılmış köşeler */
    font-size: 16px;           /* Font boyutu */
    cursor: pointer;          /* Fare ile üzerine gelindiğinde tıklanabilir simge */
    transition: background-color 0.3s ease; /* Arka plan renginin geçişi */
  }
  
  /* Butona fare ile gelindiğinde renk değişikliği */
  .mySubmitButton:hover {
    background-color: #45a049; /* Daha koyu yeşil */
  }
  
  
  
  